import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://b5ab9fa7131cb6b8b19f829dbfaf956c@sentry.smlabs.es/17",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});